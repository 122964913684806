import React from "react";
import shape01 from "../../assets/img/logo/logo-desplegable.png";
import shape02 from "../../assets/img/banner/banner_shape02.png";
import shape03 from "../../assets/img/banner/banner_shape03.png";
import fireIcon from "../../assets/img/icon/fire.png";
import CountDownOne from "../CountDown/CountDownOne";
import fondo from "../../assets/img/git/PIAapuesta imagen .png";
//Pagina Encabezado de Inicio Home 1
const Banner = (props) => {
  return (
    <section id="sales" className="chart-area banner-bg ">
      <div className="container-fluid">
        <div className="chart-inner">
          <div className="row align-items-center justify-content-center me-5">
            <div className="col-lg-12 col-md-12 order-0 order-lg-2">
                <img src={fondo} alt="" />
            </div>

          </div>
          <div className="row justify-content-center mt-5 ">
          <div className="col-lg-4 mr-3">
                <div className="row">
                  <div className="col-md-12 align-items-center text-center mb-5">
                  <button style={{  content: "none"}} type="button" class="btn btn-success btn-lg py-4">APUESTA</button>
                  </div>

                </div>
                
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
