import React from "react";
import chartImg from "../../assets/img/images/grafico-final.png";
import SalesTabButton from "./SalesTabButton";
import SalesTabContent from "./SalesTabContent";
import repr from "../../assets/img/logo/logoMus.png";
import imag1 from "../../assets/img/logo/Trading.jpeg"
import imag2 from "../../assets/img/logo/Apuesta.jpeg";
import imag3 from "../../assets/img/logo/Noticias.jpeg";
import { useState } from "react";
import Initial from "../../components/Sales/Initial";
import TopPartners from "../../components/TopPartners/TopPartners";

const Begin = ({setVisible,setDuelos,setRedes }) => {
  const [inicio,setinicio]=useState(false);

  const duelos = ()=>{
    setDuelos(true);
    setVisible(false);
    setinicio(false);
  }
  const redes = ()=>{
    setRedes(true);
    setVisible(false);
    setinicio(false);
  }
  return (
    <>
    <section id="reproductor" className="chart-area chart-bg2 ">
      <div className="container">
        <div className="row mt-1">
        </div>
        <div className="row justify-content-between mt-10 ">
        <div className="col-md-4 text-end" >
         <button className="bg-transparente " onClick={()=>duelos()}><img src ={imag2} width={300} class="rounded-top" /></button>
          </div>
          <div className="col-md-4 text-center" >
         <button className="bg-transparente" onClick={()=>setinicio(true)}><img src ={imag1} width={300} class="rounded-top"/></button>
          </div>
          <div className="col-md-4 ">
          <button className="bg-transparente" onClick={()=>redes()}><img src ={imag3} width={300} class="rounded-top"  /></button>
         
          </div>
        </div>
      </div>
    </section>
    {inicio && <Initial />}
    {inicio && <TopPartners />}

  </>
  );

};

export default Begin;
